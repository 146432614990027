<template>
  <div>
    <ott-button text="Open" :click="openModal" />
    <add-provider-modal />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import AddProviderModal from "@/components/addProviderModal/AddProviderModal";
import OttButton from "@/components/vuetifyComponents/OttButton";
export default {
  name: "PictureCuter",
  components: {
    OttButton,
    AddProviderModal
  },
  methods: {
    ...mapMutations({
     openModal: 'addProviderModal/openModal '
    })
  }
};
</script>

<style lang="scss" scoped></style>
